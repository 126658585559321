import React from "react"
import Layout from "../components/Layout"
import {
  Block,
  LeadP,
  P
} from "../theme/page-elements/elements"

export default function Index() {

  return (
    <Layout>
      <Block>
        <LeadP> 
          Page Not Found
        </LeadP>
        <P>
          The requested content is not found.
        </P>
      </Block>
    </Layout>
  )
}
